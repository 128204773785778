import React from "react";
import ParticlesBackground from "../common/ParticlesBackground";

const NotFound: React.FC = () => (
    <div>
        <div className="title-center">404</div>
        <div className="text-center">Nothing to see here</div>
        <ParticlesBackground />
    </div>
);

export default NotFound;
