import { version as appVersion } from "../../package.json";

type Environment = "local" | "qa" | "dev" | "master";

export const getEnvironment = (host: string): Environment => {
    switch (host) {
        case "hangeland.no":
        case "www.hangeland.no":
            return "master";
        case "localhost:3000":
            return "local";
        default:
            throw new Error(`Unrecognized application environment: ${host}.`);
    }
};

export const name = "Hangeland.no";

export const version = appVersion;

export const environment = getEnvironment(window.location.host);

export const baseUrl = `${window.location.protocol}//${window.location.host}`;

console.info(`Hangeland.no v${version} running in ${environment} environment.`);
