import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import CacheBuster from "./components/providers/CacheBusterProvider";

const history = createBrowserHistory();
ReactDOM.render(
    <React.StrictMode>
        <CacheBuster>
            <Router history={history}>
                <App />
            </Router>
        </CacheBuster>
    </React.StrictMode>,
    document.getElementById("root")
);
