import React, { useState, useEffect, ReactChild } from "react";
import { version } from "../../config/app-config";
import ParticlesBackground from "../common/ParticlesBackground";

// Code reference:
// https://dev.to/flexdinesh/cache-busting-a-react-app-22lk

const refreshCacheAndReload = (): void => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete().
        caches.keys().then((keys) => keys.forEach((key) => caches.delete(key)));
    }
    console.info("Browser reloaded due to new version");
    // Delete browser cache and hard reload.
    window.location.reload();
};

interface CacheBusterProps {
    children: ReactChild | ReactChild[];
}

const CacheBuster = (props: CacheBusterProps) => {
    const [versionChecked, setVersionChecked] = useState(false);
    const { children } = props;

    useEffect(() => {
        /* if (environment === "local") {
            setVersionChecked(true);
            return;
        } */

        fetch("/meta.json", {
            headers: {
                pragma: "no-cache",
                "cache-control": "no-cache",
            },
        })
            .then((response) => response.json())
            .then((meta) => {
                const currentVersion = version;
                const latestVersion = meta.version;

                const shouldForceRefresh = latestVersion !== currentVersion;
                if (shouldForceRefresh) {
                    refreshCacheAndReload();
                } else {
                    setVersionChecked(true);
                }
            })
            .catch(() => {
                console.error("meta.json not found");
            });
    }, []);

    if (!versionChecked) {
        return <ParticlesBackground />;
    }
    return <React.Fragment>{children}</React.Fragment>;
};

export default CacheBuster;
