import React from "react";
import ParticlesBackground from "../common/ParticlesBackground";
import "./index.css";

const HomePage: React.FC = () => (
    <div>
        <div className="title-center">Welcome</div>
        <ParticlesBackground />
    </div>
);

export default HomePage;
